import { BaseModel } from "./base.model";
import { RequestType } from "./easydiadem/request.model";
import { IPrinter } from "./printer.model";
import { FlowType, Proofreading } from "./proofreading.model";
import { IUser, User } from "./users.model";
import { ICustomer } from "./customer.model";
import { Member } from "./members.model";

export enum State {
    LIVE = 'live',
    ARCHIVED = "archived",
}

export interface IProject {
    _id: string;
    birth: Date;
    type: string;
    state: string;
    name: string;
    folders: { url: string, tag: string; }[];
    identifier: string;
    description: string;
    project_id: string;
    custom: ICustomData;
    previews: any;
}

export interface ICustomData {
    general_info: IGeneralInfo;
    easydiadem?: IEDEmbededRequest;
}

export interface IGeneralInfo {
    cppID?: string;
    customer: ICustomer;
    customCode?: string;
    customerInvoice?: ICustomer;
    nombre_ref: number;
    id_operateur: string;
    marque: string;
    designation: string;
    contact: IContact;
    cpp: IUser;
    l2pub: IL2PUB;
    family: string;
    agency: string;
    lang: string;
}

export interface IContact {
    _id: string;
    UUID: string;
    IDAdresse: number;
    IDClient: number;
    IDSociete: number;
    social_reason: string;
    contact: string;
    lastname: string;
    firstname: string;
    paymentTerms: string;
    numCompta: string;
    email: string;
    fonction: string;
    localisation: number;
    devise: string;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    postalCode: string;
    country: string;
    telephone: string;
    creation_date: string;
}

export interface IL2PUB {
    UUID: string,
    NumDossier: string,
    IDDossier: number;
    IDClient: number;
    Etat: string;
    Affaire: string;
}


// export interface ICustomer {
//     _id: string,
//     UUID: string,
//     code: string,
//     IDClient: number,
//     IDSociete: number,
//     name: string,
//     group: string,
//     creation_date: string;
// }


// Master project

export interface IMasterCustomData extends ICustomData {
    general: IGeneralMaster;
}

export interface IGeneralMaster {
    id_maitre: string;
    final_name: string;
    is_old?: boolean;
}

// Child project
export interface IChildProject extends IProject {
    custom: IChildCustomData;
}

export interface IChildCustomData extends ICustomData {
    general: IGeneralChild;
    l2pub: IL2PUB;
    colors: IColors;
    members: Member[];
    comments?: any;
    cartouche?: ICartouche;
    support: any;
    crea: any;
    exe: any;
    hd: any;
    repro: IRepro;
    easydiadem?: IEDEmbededRequest;
    proofreadings?: Proofreading[];
    riverflow?: IRiverflowEmbededData;
}


export interface IColors {
    impression_nombredecouleurmax: string;
    mec_reel: number;
    impression_couleur1: string;
    impression_couleur2: string;
    impression_couleur3: string;
    impression_couleur4: string;
    impression_couleur5: string;
    impression_couleur6: string;
    impression_couleur7: string;
    impression_couleur8: string;
    impression_couleur9: string;
    impression_couleur10: string;
    comm_seq_colors: string;
    impression_finition1: string;
    impression_finition2: string;
    impression_finition3: string;
    impression_finition4: string;
    impression_finition5: string;
    impression_finition6: string;
    impression_finition7: string;
    impression_finition8: string;
    impression_finition9: string;
    id_refcommune: string;
    id_couleurcommune: string;
    gencod_type: string;
    gencod_code: string;
    gencod_couleur: string;
    gencod_taille: string;
    gencod_rlb: string;
    datamatrix: string;
    datamatrix_code: string;
    datamatrix_couleur: string;
    datamatrix_taille: string;
    datamatrix_rlb: string;
    qrcode_adresse: string;
    adresseqr: string;
    qrcode: string;
    qrcode_couleur: string;
    qrcode_taille: string;
    qrcode_correction: string;
    qrcode_rlb: string;
}

export interface IGeneralChild {
    id_maitre: string;
    id_jobteam: string;
    id_codearticle: string;
    id_codearticle_info: string;
    type_job: string;
    crea_nom: string;
    crea_type: string;
    crea_valideedate: string;
    id_master: string;
    imprimeur: IPrinter;
    printerConstraints?: IPrinterConstraints;
    plan_technique: string;
    support: any;
    comm_printer: string;
    id_archive: string;
    commentaire_gamme: string;
    // relecturejob_exe: number;
    // relecturejob_repro: number;
    service_exe: string | boolean;
    service_hd: string | boolean;
    service_repro: string | boolean;
    service_other: string | boolean;
    process: any;
    IDDossier?: number;
    numDossier?: string;
    final_name?: string;
    riverflow?: IRiverflowEmbededData;
    is_old?: boolean;
}

export interface ICartouche {
    operateur_exe: User;
    operateur_hd: User;
    operateur_repro: User;
    qualite_image: string;
    textMinPos: string;
    textMinNeg: string;
    filetMinPos: string;
    filetMinNeg: string;
    policeUsed: string;
    hautXMin: string;
    hautChiffrePoids: string;
    hautEMetro: string;
    url_exe?: string;
    url_repro?: string;
}

export interface IPdfRepository {
    pr_id: string,
    pr_status: string,
    pr_status_date: String,
}


export interface IEDEmbededRequest {
    _id: string,
    ref: string,
    type: RequestType,
    flow: FlowType | null,
    pdf_repository?: IPdfRepository;
    // briefing?: IBriefingRequest;
}

export interface IRiverflowEmbededData {
    createJob: boolean,
    jobDate: Date,
    jobTypes: string[];
}

export interface IPrinterConstraints {
    filetMinPos: string;
    filetMinNeg: string;
    textMinPos: string;
    textMinNeg: string;
    secuCoupe: string;
    secuRain: string;
    colorDebDev: string;
    colorDebLaize: string;
}

export interface IRepro {
    repro_fichier?: string;
    repro_trapping?: string;
    repro_retrait?: string;
    repro_vernis?: string;
    repro_blanc?: string;
    repro_trame?: string;
    repro_profil?: string;
    repro_cible?: string;
    repro_pointmini?: string;
    repro_pointmaxi?: string;
    repro_tauxencragemax?: string;
    commentaire_imprimeur?: string;
    commentaire_preparation?: string;
    commentaire_cpp?: string;
}
// project table

// export interface IMasterProjectTable {
//     identifier: string,
//     name: string,
//     description: string;
//     childs?: IChildProjectTable[];
// }


// export interface IChildProjectTable {
//     _id: string,
//     identifier: string,
//     name: string,
//     id_maitre: string,
//     relecturejob: string,
//     pr: IPrProjectTable;
// }


// // project Proofreading

// export interface PrProject {
//     name: string;
//     description?: string;
//     codeArticle?: string;
//     proofReadingID: string | undefined;
// }


export class Project extends BaseModel<IProject> implements IProject {
    folders: { url: string, tag: string; }[];
    _id: string;
    birth: Date;
    type: string;
    state: string;
    name: string;
    identifier: string;
    description: string;
    project_id: string;
    custom: ICustomData;
    previews: any;

    constructor(data: Partial<IProject> = {}) {
        super(data);
        this.init(data);
    }
}

export class ChildCustomData implements IChildCustomData {
    protected __build: { [path: string]: new (data: any) => any; } = {
        members: Member,
        proofreadings: Proofreading,
    };

    general_info: IGeneralInfo;
    general: IGeneralChild;
    colors: IColors;
    members: Member[];
    comments?: any;
    cartouche?: ICartouche;
    repro: IRepro;
    support: any;
    crea: any;
    exe: any;
    hd: any;
    l2pub: IL2PUB;
    easydiadem?: IEDEmbededRequest;
    proofreadings?: Proofreading[];
    riverflow?: IRiverflowEmbededData;
}

export class ChildProject extends Project {
    protected __build: { [path: string]: new (data: any) => any; } = {
        'custom.proofreadings': Proofreading,
        'custom.members': Member,
    };

    custom: IChildCustomData;

    constructor(data: Partial<IProject> = {}) {
        super(data);
        this.init(data);
    }
}

// export class MasterCustomData implements IMasterCustomData {
//     general_info: IGeneralInfo;
//     general: IGeneralMaster;
// }

export class MasterProject extends Project {

    custom: IMasterCustomData;

    constructor(data: Partial<IProject> = {}) {
        super(data);
        this.init(data);
    }
}