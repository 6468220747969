<div id="alert" class="d-flex justify-content-between align-items-center">
    <span [ngClass]="classes">
        <ng-container *ngIf="isMessage()">
            <mat-icon class="mr-2">feedback</mat-icon>
        </ng-container>

        <ng-container *ngIf="isSuccess()">
            <mat-icon class="mr-2">check_circle</mat-icon>
        </ng-container>

        <ng-container *ngIf="isError()">
            <mat-icon class="mr-2">error</mat-icon>
        </ng-container>

        <span>{{ inject() }}</span>
    </span>
    <!-- <button mat-button></button> -->
</div>