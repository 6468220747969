import { FilePathDescriptor, IRequest, Request, RequestStatus, RequestType } from "../request.model";
import { CustomerBrief, ICustomerBrief, WorkSubject } from "./briefing/customer-brief.model";
import * as _ from "lodash";
import { CreaFiles, ICreaFiles } from "./briefing/crea-files.model";
import { IImagesToRetouch, ImagesToRetouch } from "./briefing/images-to-retouch.model";
import { ArchiveExistingReference, IArchiveExistingReference } from "./briefing/archive-existing-reference.model";
import { ITechnicalPlan, TechnicalPlan, TechnicalPlanType } from "./briefing/technical-plan.model";
import { ITextSheet, TextSheet, TextSheetType } from "./briefing/text-sheet.model";
import { IPrintingMethod, PrintingMethod } from "./briefing/printing-method.model";
import { Coloring, IColoring } from "./briefing/coloring.model";
import { BrandChart, IBrandChart } from "./briefing/brand-chart.model";
import { Deadline, IDeadline } from "./briefing/deadline.model";
import { IValidationCycle, ValidationCycle } from "./briefing/validation-cycle.model";
import { ClientContact, IClientContact } from "./briefing/client-contact.model";
import { CustomerService } from "src/app/services/customer.service";
import { AuthService } from "src/app/services/auth.service";

export interface IBriefingRequest extends IRequest {
    num_job?: string;
    customer_brief: ICustomerBrief;
    crea_files: ICreaFiles;
    images_to_retouch: IImagesToRetouch;
    archive_existing_reference: IArchiveExistingReference;
    technical_plan: ITechnicalPlan;
    text_sheet: ITextSheet;
    printing_method: IPrintingMethod;
    coloring: IColoring;
    brand_chart: IBrandChart;
    deadline: IDeadline;
    validation_cycle?: IValidationCycle;
    client_contact?: IClientContact;
}

export class BriefingRequest extends Request implements IBriefingRequest {
    public static FILES: FilePathDescriptor = [
        { key: 'customer_brief.brief_url' },
        { key: 'archive_existing_reference.files' },
        { key: 'brand_chart.files' },
        { key: 'crea_files.files' },
        { key: 'images_to_retouch.files' },
        { key: 'printing_method.printerSpecifications' },
        { key: 'technical_plan.newRefFile' },
        { key: 'technical_plan.updateRefFile' },
        { key: 'text_sheet.file' },
    ];

    override type: RequestType = RequestType.BRIEFING;
    protected override __build: { [path: string]: new (data: any) => any; } = {
        customer_brief: CustomerBrief,
        crea_files: CreaFiles,
        images_to_retouch: ImagesToRetouch,
        archive_existing_reference: ArchiveExistingReference,
        technical_plan: TechnicalPlan,
        text_sheet: TextSheet,
        printing_method: PrintingMethod,
        coloring: Coloring,
        brand_chart: BrandChart,
        deadline: Deadline,
        validation_cycle: ValidationCycle,
        client_contact: ClientContact
    };

    num_job?: string;
    customer_brief = new CustomerBrief();
    crea_files = new CreaFiles();
    images_to_retouch = new ImagesToRetouch();
    archive_existing_reference = new ArchiveExistingReference();
    technical_plan = new TechnicalPlan();
    text_sheet = new TextSheet();
    printing_method = new PrintingMethod();
    coloring = new Coloring();
    brand_chart = new BrandChart();
    deadline = new Deadline();
    validation_cycle? = new ValidationCycle();
    client_contact? = new ClientContact();


    override get isEditable() {
        return this.status === RequestStatus.INIT || this.status === RequestStatus.PENDING;
    }

    constructor(r: Request | BriefingRequest | any = {}) {
        super(r);
        this.init(r);
    }

    override isValid(key?: string) {
        if (key) return this.validate(key);
        return super.isValid();
    }


    protected validate(key?: string): boolean {
        const subObject = key ? _.get(this, key) : this;
        if (!subObject) {
            return false;
        }
        switch (key) {
            case "customer_brief":
                if (subObject['subject']) {
                    switch (subObject['subject']) {
                        case WorkSubject.OTHER:
                            return !!subObject['other_subject'] && !!subObject['brief_url'];
                        default:
                            return !!subObject['brief_url'];
                    }
                }
                return false;
            case "technical_plan":
                if (subObject['selection'] === TechnicalPlanType.NEW_REF) {
                    return !!subObject['newRefFile'];
                } else if (subObject['selection'] === TechnicalPlanType.UPDATE_REF) {
                    if (subObject['updateRefModification']) {
                        return !!subObject['updateRefFile'];
                    } else {
                        return true;
                    }
                }

                return false;
            case "text_sheet":
                if (subObject['selection'] === TextSheetType.ARCHIVE) {
                    return !!subObject['archive'];
                } else if (subObject['selection'] === TextSheetType.FILE) {
                    return !!subObject['file'];
                } else if (subObject['selection'] === TextSheetType.ONLINE) {
                    return !!subObject['textsID'];
                }
                return false;
            default:
                return true;
        }
    }
}