<div id="alert" class="d-flex justify-content-between align-items-center" (click)="goToURL()">
    <span class="alert-text" [ngClass]="classes">
        <ng-container *ngIf="isMessage()">
            <mat-icon class="mr-2">notifications_active</mat-icon>
        </ng-container>
        <ng-container *ngIf="isSuccess()">
            <mat-icon class="mr-2">notifications_active</mat-icon>
        </ng-container>
        <ng-container *ngIf="isError()">
            <mat-icon class="mr-2">error</mat-icon>
        </ng-container>
        <span>{{ message }}</span>
    </span>


    <!-- <button mat-button>
    <mat-icon class="mr-2 goto">near_me</mat-icon>
  </button> -->
    <button mat-button click-stop-propagation (click)="dismiss($event)">
        <mat-icon class="mr-2 dismiss">cancel</mat-icon>
    </button>
</div>