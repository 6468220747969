import { IArchiveSubRequest, IRequest, Request, RequestType } from "../request.model";
import { TranslateService } from "@ngx-translate/core";

export interface IFileRedeliveryRequest extends IRequest {
    redeliveryType: RedeliveryType;
    archive: IArchiveSubRequest;
}

export enum RedeliveryType {
    EXE = "EXE",
    REPRO = "REPRO"
}

export class FileRedeliveryRequest extends Request implements IFileRedeliveryRequest {
    override type: RequestType = RequestType.FILE_REDELIVERY;

    translate: TranslateService;

    get description(): string {
        return 'FILE_REDELIVERY_DESC';
    }

    archive: IArchiveSubRequest = {
        source_sub_path: ""
    };
    redeliveryType: RedeliveryType;

    get isLinkable(): boolean {
        return false;
    }

    constructor(data: Partial<FileRedeliveryRequest>) {
        super(data);
        this.init(data);
    }
}
