import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { GeneralError } from '@feathersjs/errors';
import { ProofreadingService } from './proofreading.service';
import { AuthService } from './auth.service';
import { Member, MemberPermission, MemberStatus } from '../models/members.model';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';

// @ts-ignore
// const api = require('@modules/cf-api.js').api;;
// const api_async = require('@modules/cf-api.js').api_async;

@Injectable({
    providedIn: 'root',
})
export class MembersService {

    get members() {
        return this.s_proofreadings.current?.members || [];
    }

    get current_member() {
        return this.members.find(member => {
            return member.usersID.includes(this.s_auth.user?._id || '');
        });
    }

    // CURRENT MEMBER PRIVILEGES
    get isApproverDocument(): boolean {
        return !!this.current_member?.hasApproDocument;
    }
    get isApproverInfo(): boolean {
        return !!this.current_member?.hasApproInfo;
    }
    get hasDecision(): boolean {
        return this.s_auth.isCPP()
            || this.s_auth.isOperator()
            || this.s_auth.isFab()
            || (this.s_auth.isClient() && this.isApproverDocument || this.isApproverInfo);
    }

    constructor(
        protected s_auth: AuthService,
        protected s_proofreadings: ProofreadingService,
    ) { }

    public getMembersByPerms(permsRequested: MemberPermission[]) {
        return _.filter(this.members, member => this.hasPermission(permsRequested, '&', member.permissions));
    }

    public addMainApprover(approver: Member, members: Member[]) {
        const current_main_index = members.findIndex(m => m.hasApproDocument);
        if (current_main_index !== -1) {
            approver.permissions = _.uniq([MemberPermission.APPROVAL, ...approver.permissions]);
            members[current_main_index] = approver;
        }
        else {
            members.push(approver);
        }
        return _.uniqWith([approver, ...members], (a, b) => _.isEqual(a.usersID[0], b.usersID[0]));
    }

    public hasPermission(permsRequested: MemberPermission[], operator: string, user_perms?: string[]) {
        if (operator !== '&' && operator !== '|') {
            throw new GeneralError('NO operator set !');
        }
        const perms = user_perms || this.current_member?.permissions || [];
        return operator === '&' ? _.every(permsRequested, perm => _.includes(perms, perm)) : _.some(permsRequested, perm => _.includes(perms, perm));
    }

    public hasStatus(statusRequested: string[], operator: string) {
        if (operator !== '&' && operator !== '|') {
            throw new GeneralError('NO operator set !');
        }
        const status = this.current_member?.status || MemberStatus.PENDING;
        return operator === '&' ? _.every(statusRequested, st => st.includes(status)) : _.some(statusRequested, st => st.includes(status));
    }
}
