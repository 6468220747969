import { ProjectService } from "src/app/services/project.service";
import { IRelectureSubRequest, IRequest, Request, RequestStatus, RequestType } from "../request.model";
import * as _ from "lodash";
import { FlowType, Proofreading } from "../../proofreading.model";
import { IMember } from "../../members.model";
import { Id } from "../../base.model";
import { IUploadedFile } from "src/app/services/file.service";

export interface IPDFRepositoryRequest extends IRequest {
    file?: IUploadedFile;
}

export class PDFRepositoryRequest extends Request implements IPDFRepositoryRequest {
    override type: RequestType = RequestType.PDF_REPOSITORY;
    override flow: FlowType | null = FlowType.EZD_PDF_REPOSITORY;
    file?: IUploadedFile;

    override relecture: IRelectureSubRequest = {
        proofreadingsID: [],
        members: []
    };

    override get isEditable(): boolean {
        return true;
    }

    constructor(data: Partial<PDFRepositoryRequest>) {
        super(data);
        this.init(data);
    }

    onProjectCreation(service: ProjectService, lifecycle: "init" | "submit"): Promise<void> | void {
        switch (lifecycle) {
            case "init":
                this.insertCommCustomerInProject(service);
                this.insertRequestMemberInProjectMember(service);
                this.setServiceInProject(service);
                break;
            case "submit":
                break;
        }
        super.onProjectCreation(service, lifecycle);
    }

    private insertRequestMemberInProjectMember(service: ProjectService) {
        // Set request member in project member
        _.set(service.childData, 'custom.members', this.relecture?.members);
        // flag the easydiadem service so member edit is disabled
        service.s_requests.setFlag("project-members-creation-locked", true);
    }

    private insertCommCustomerInProject(service: ProjectService) {
        _.set(service.childData, 'custom.other.commentaire_cpp', this?.finalization?.comment);
    }

    private setServiceInProject(service: ProjectService) {
        _.set(service.childData, 'custom.general.service_exe', false);
        _.set(service.childData, 'custom.general.service_repro', false);
        _.set(service.childData, 'custom.general.service_hd', false);
        _.set(service.childData, 'custom.general.service_other', true);
    }
}
