import { ITranslatedText } from "./models/production-texts.model";


//#region PRODUCTION TEXTS
export const languages = ['fr', 'en', 'de', 'nl', 'es', 'pt', 'it', 'el', 'pl', 'cs', 'da', 'no', 'ro', 'lv', 'sk', 'he', 'ru', 'uk', 'fin', 'sv', 'ar', 'zh', 'ko', 'mx'];

/**
 * Default texts used in the production texts
 */
export const defaultTexts: ITranslatedText[] = [
    {
        label: 'Dénomination légale de vente',
        type: 'legal',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Liste d\'ingrédients',
        type: 'legal',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Suggestion de présentation',
        type: 'legal',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Info Consigne de tri',
        type: 'legal',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Textes conservation / DLUO',
        type: 'legal',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Adresse',
        type: 'legal',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Poids net',
        type: 'legal',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Code EAN',
        type: 'legal',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Dénomination commerciale',
        type: 'marketing',
        position: { facing: false, back: true },
        langs: {}
    },
    {
        label: 'Conseil de préparation',
        type: 'marketing',
        position: { facing: false, back: true },
        langs: {}
    },
];

// TVN default values
export const defaultTVN = [
    ['Valeurs moyennes pour :', ['100', 'g/ml'], ['Une portion', '', ''], '% AR / portion'],
    ['Énergie (kJ)', '', '', ''],
    ['Énergie (kcal)', '', '', ''],
    ['Matières grasses (g)', '', '', ''],
    ['dont acides gras saturés (g)', '', '', ''],
    ['dont acides gras mono-insaturés (g)', '', '', ''],
    ['dont acides gras poly-insaturés (g)', '', '', ''],
    ['Glucides (g)', '', '', ''],
    ['dont sucres (g)', '', '', ''],
    ['dont polyinsaturés (g)', '', '', ''],
    ['dont amidon (g)', '', '', ''],
    ['Fibres alimentaires (g)', '', '', ''],
    ['Protéines (g)', '', '', ''],
    ['Sodium (g)', '', '', ''],
    ['Calcium (g)', '', '', ''],
    ['Sel (g)', '', '', ''],
];

export const TVMHeadersMeta = [
    { style: 'font-bold text-xl' },
    { style: 'font-bold' },
    { style: 'font-bold' },
    { style: 'font-bold' },
    {},
    {},
    {},
    { style: 'font-bold' },
    {},
    {},
    {},
    { style: 'font-bold' },
    { style: 'font-bold' },
    { style: 'font-bold' },
    { style: 'font-bold' },
    { style: 'font-bold' },
];


//#endregion