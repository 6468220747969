import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {
    FormBuilder,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CFAPIService } from 'src/app/services/api.service';
import { IUser } from 'src/app/models/users.model';
import { FormGroupComponent } from 'src/app/shared/pages/form-group/form-group.component';
import { AuthService } from 'src/app/services/auth.service';
import { LivecoAPIService } from 'src/app/services/liveco-api.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// @ts-ignore
// const api = require('@modules/cf-api.js').api;;
// const api_async = require('@modules/cf-api.js').api_async;

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MyAccountComponent extends FormGroupComponent<IUser> implements OnInit {

    user: IUser;
    // valid: Observable<boolean>;
    initialLang: string;

    override get id() {
        return this.s_auth.user?._id || 'none';
    }

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected s_alert: AlertService,
        protected cd: ChangeDetectorRef,
        protected s_loader: LoaderService,
        protected dialog: MatDialog,
        private fb: FormBuilder,
        protected apiCF: CFAPIService,
        protected s_auth: AuthService,
        protected liveco: LivecoAPIService,
        protected translate: TranslateService,

    ) {
        super(route, router, s_alert, s_loader, s_auth, translate);
        this.initialLang = this.translate.currentLang;
    }

    submitForm(): void {
        this.form.markAllAsTouched();
        // let form = this.formUser.getRawValue();
        // console.log("Form : ", form);

        if (this.form.valid) {
            this.s_loader.loading = true;
            let form = this.form.getRawValue();
            this.form.markAsPristine();
            this.updateUser(form);
        }
    }

    userForm(form: any) {
        let user: any = {
            _id: this.id,
            username: form.username,
            firstName: form.firstName,
            lastName: form.lastName,
            fullname: form.firstName + ' ' + form.lastName,
            email: form.email,
            customer: form.customer,
            marque: form.range,
            groups: form.groups,
            language: form.language,
        };
        return user;
    }

    async updateUser(form: any) {
        let user = this.userForm(form);
        if (this.id) {
            const res = await this.liveco.patch(this.id, '/users', user);
            if (res && res._id) {
                this.s_loader.loading = false;
                this.s_alert.success("USER.EDIT_ACCOUNT_SUCCESS");

                const newUser = res;

                if (this.s_auth.user?._id === newUser._id) {
                    this.s_auth.user = newUser;
                }

                if (this.s_auth.user && this.s_auth?.user?.lang !== this.initialLang) {
                    this.initialLang = this.s_auth.user.lang;

                    window.location.reload();
                }
            }
        }
    }
}