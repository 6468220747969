
import * as _ from 'lodash';
import { BaseModel, Id } from 'src/app/models/base.model';

export class Helpers {


    /**
     * Is an Id
     * @param value 
     */
    static isId(val: any): boolean {
        return (
            val &&
            !_.isArray(val) &&
            /[a-fA-F0-9]{24}/.test(val)
        );
    }


    /**
     * Is a real Model
     * @param value 
     */
    public static isModel(value: any): boolean {
        return value && _.isString(value.className) && _.isFunction(value.sanitize);
    }


    /**
     * Is not null AND not undefined
     * @param value 
     */
    public static isSet(value: any): boolean {
        return value !== null && value !== undefined;
    }

    /**
     * Check if the field id, _primaryKey or _id are set
     * throw if entity is null
     * @param entity 
     */
    static hasId(entity: any): boolean {
        if (entity) {
            return entity.id || entity._primaryKey || entity._id;
        } else {
            throw '[Helper HasID] entity is null';
        }
    }

    /**
   * return the values of the field id, _primaryKey or _id
   * throw if entity is null
   * @param entity 
   */
    static getId(entity: any): Id {
        if (_.isString(entity)) {
            return entity;
        } else
            if (entity) {
                return entity.id || entity._primaryKey || entity._id;
            } else {
                throw '[Helper getID] entity is null';
            }
    }


    /**
     * 
     * @param entity Check if entity is a model, if not instanciate a new one
     * @param model 
     * @returns 
     */
    static toModel(entity: any, model: new (any: any) => any): any {
        if (Helpers.isModel(entity)) {
            return entity;
        } else {
            return new model(entity);
        }
    }

    /**
     * Array/Object/any is empty
     * @param value 
     */
    public static isEmpty(value: any): boolean {
        if (_.isArray(value) && value.length === 0) {
            return true;
        } else if (_.isString(value) && value.trim() === '') {
            return true;
        } else if (_.isNumber(value) && value === 0) {
            return true;
        } else if (_.isObject(value) && Object.keys(value).length === 0) {
            return true;
        }

        return false;
    }


    /**
     * Is a real number
     * @param value 
     */
    public static isRealNumber(value: any): boolean {
        return Helpers.isSet(value) && !isNaN(parseInt(value)) && _.isNumber(parseInt(value));
    }


    /**
     * Check if the array contains only Id
     */
    public static isArrayId(arr: any[]): boolean {
        let check: boolean = true;

        if (_.isArray(arr)) {
            arr.forEach(x => check = !Helpers.isId(x) ? false : check);
        } else {
            check = false;
        }

        return check;
    }


    /**
     * Check if the array contains only Model
     */
    public static isArrayModel(arr: any[]): boolean {
        let check: boolean = true;

        if (_.isArray(arr)) {
            arr.forEach(x => check = !Helpers.isModel(x) ? false : check);
        } else {
            check = false;
        }

        return check;
    }


    /**
     * Check if array contains a model
     * @param arr 
     * @param model 
     */
    public static containsModel(arr: any[], model: BaseModel): boolean {
        let check: number = -1;

        if (_.isArray(arr)) {
            let check = arr.findIndex((x: any) => Helpers.isModel(x) && x._id?.toString() === model._id?.toString());
        }

        return check !== -1;
    }

    /**
     * Return file as encoded dataURI
     * @param file 
     * @returns 
     */
    static toDataURL(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise((resolve, reject) => {
            try {
                // Encode the file using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    console.log('[ENCODED FILE]', file.name);
                    resolve(reader.result);
                    // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
                };
                reader.readAsDataURL(file);
            } catch (err) {
                console.error(err);
                reject(err);
            }
        });
    }

    /**
   * Return file as encoded dataURI
   * @param file 
   * @returns 
   */
    static toFormData(file: File): FormData {
        const fd = new FormData();
        fd.append('uri', file);
        return fd;
    }

    /**
     * Deep comparison between two array of object, return true in case of equality
     * @param x 
     * @param y 
     * @returns 
     */
    static isArrayEqual(x: any[], y: any[]) {
        return _(x).differenceWith(y, _.isEqual).isEmpty() && _(y).differenceWith(x, _.isEqual).isEmpty();
    }
}
