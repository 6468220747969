import { ProjectService } from 'src/app/services/project.service';
import { IArchiveSubRequest, IRequest, Request, RequestType } from '../request.model';
import * as _ from 'lodash';


export enum shooting {
  FACE = "SHOOTINGSIDE.FACE",
  THREE_QUARTER_LEFT = "SHOOTINGSIDE.THREEQUARTERLEFT",
  THREE_QUARTER_RIGHT = "SHOOTINGSIDE.THREEQUARTERRIGHT",
  FACE_INCO = "SHOOTINGSIDE.FACEINCO",
}

export interface IPackshotRequest extends IRequest {
  shooting: shooting[];
  archive: IArchiveSubRequest;
}

export class PackshotRequest extends Request implements IPackshotRequest {

  override type: RequestType = RequestType.PACKSHOT;

  shooting: shooting[];

  get description(): string {
    return this.shooting.join(", ");
  }

  archive: IArchiveSubRequest = {
    source_sub_path: '3. HD',
  };

  override options: any = {
    ...this.options,
    rvf: {
      jobType: 'EXE',
      dateIncrement: 2
    },
  };

  constructor(data: Partial<PackshotRequest>) {
    super(data);
    this.init(data);
  }

  onProjectCreation(service: ProjectService, lifecycle: 'init' | 'submit'): Promise<void> | void {
    switch (lifecycle) {
      case 'init':
        this.insertCommCustomerInProject(service);
        break;
      case 'submit':

        break;
    }
    super.onProjectCreation(service, lifecycle);
  }

  private insertCommCustomerInProject(service: ProjectService) {
    // Set request comm customer, name and reset service
    _.set(service.childData || {}, 'custom.exe.commentaire_cpp', this?.shooting.join(", "));
  }

}
