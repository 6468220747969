import { ProjectService } from 'src/app/services/project.service';
import { IArchiveRequest, IArchiveSubRequest, IRequest, Request, RequestType } from '../request.model';
import * as _ from 'lodash';
import { IUploadedFile } from 'src/app/services/file.service';
import { IIsolationRequest } from './isolation-request.model';

export interface IHeroImageRequest extends IRequest {
  description: string;
  file?: IUploadedFile;
  archive: IArchiveSubRequest;
}


export class HeroImageRequest extends Request implements IHeroImageRequest, IArchiveRequest {
  override type: RequestType = RequestType.HERO_IMAGE;

  description: string;
  // Used to retrieve the downloaded PDF on liveCo
  file?: IUploadedFile;

  archive: IArchiveSubRequest = {
    source_sub_path: '3. HD',
  };

  override options: any = {
    ...this.options,
    rvf: {
      jobType: 'HD',
      dateIncrement: 2
    },
  };

  constructor(data: Partial<IHeroImageRequest>) {
    super(data);
    this.init(data);
  }

  onProjectCreation(service: ProjectService, lifecycle: 'init' | 'submit'): Promise<void> | void {
    switch (lifecycle) {
      case 'init':
        this.insertCommCustomerInProject(service);
        break;
      case 'submit':

        break;
    }
    super.onProjectCreation(service, lifecycle);
  }

  private insertCommCustomerInProject(service: ProjectService) {
    // Set request comm customer, name and reset service
    _.set(service.childData || {}, 'custom.exe.commentaire_cpp', this.description)
  }
}
