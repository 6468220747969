import { BaseModel } from "src/app/models/base.model";


export interface IColoring {
    selection: ColoringType;
    newRefComment?: string[];
    updateRefModification?: boolean;
    updateRefComment?: string[],
    comment?: string;
}

export enum ColoringType {
    NEW_REF = "new-ref",
    UPDATE_REF = "update-ref",
    NO_INFO = "no-info"
}


export class Coloring extends BaseModel<IColoring> implements IColoring {

    selection: ColoringType = ColoringType.NO_INFO;
    newRefComment?: string[] = [];
    updateRefModification?: boolean = false;
    updateRefComment?: string[] = [];
    comment?: string = undefined;

    constructor(data: Partial<Coloring> = {}) {
        super();
        this.patch(data);
    }
}