import { BaseModel } from './base.model';
import { Customer } from './customer.model';

export type UserType = 'CPP' | 'OPERATOR' | 'CLIENT' | 'ADMIN';

export const USER_LANGUAGES = {
    en: {
        key: 'en',
        label: 'English'
    },
    fr: {
        key: 'fr',
        label: 'Français'
    }
};

export enum UserPermission {
    CPP = 'CPP',
    OPERATOR = 'OPERATOR',
    CLIENT = 'CLIENT',
    ADMIN = 'ADMIN',
    FAB = 'FAB'
}

export interface IMinimalUser {
    _id: string;
    username: string;
    firstname: string;
    lastname: string;
    fullname: string;
}

export interface IUser {
    _id: string;
    username: string;
    firstName: string;
    lastName: string;
    fullname: string;
    email: string;
    permissions: any[];
    attributes_name: string[];
    customer?: Customer;
    marque?: string;
    scope_name?: string[];
    isDiadem: boolean;
    lang: string;
    language?: string;
}

export class User extends BaseModel<IUser> implements IUser {
    get isDiadem(): boolean {
        return !this.attributes_name?.includes(UserPermission.CLIENT) && !this.customer;
    }

    protected override __build: { [path: string]: new (data: any) => any; } = {
        customer: Customer
    };

    get lang(): string {
        return this.language || USER_LANGUAGES.fr.key;
    }

    _id: string;
    username: string;
    firstName: string;
    lastName: string;
    fullname: string;
    email: string;
    attributes_name: string[];
    permissions: any[];
    customer?: Customer;
    marque?: string;
    scope_name?: string[];
    language?: string;

    constructor(data: Partial<User>) {
        super(data);
        this.init(data);
    }
}
