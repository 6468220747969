import { ProjectService } from 'src/app/services/project.service';
import { IContactSubRequest, IRequest, Request, RequestType } from '../request.model';



export interface INewEpsonRequest extends IRequest {
  quantity: number;
  epson_contact: IContactSubRequest;
}

export class NewEpsonRequest extends Request implements INewEpsonRequest {

  override type: RequestType = RequestType.NEW_EPSON;

  quantity: number;
  epson_contact: IContactSubRequest = {
    address: {
      city: '',
      cp: '',
      street: '',
    },
    email: '',
    firstname: '',
    lastname: '',
    tel: '',
  };

  get isLinkable(): boolean {
    return false;
  }

  override options: any = {
    ...this.options,
    rvf: {
      jobType: 'epson',
      dateIncrement: 0
    },
  };

  constructor(data: Partial<NewEpsonRequest>) {
    super(data);
    this.init(data);
  }
}
