import * as _ from 'lodash';
import { AuthenticationClient } from '@feathersjs/authentication-client/lib';
import { AuthenticationResult } from '@feathersjs/authentication/lib';
import { Router } from '@angular/router';
import { KFeathers } from './api/feathers.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { User } from '../models/users.model';
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { USER_TYPE_ADMIN, USER_TYPE_CPP, USER_TYPE_FAB, USER_TYPE_OPERATOR, USER_TYPE_CLIENT } from 'src/app/models/usersTypes.model';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Application } from '@feathersjs/feathers';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { UtilsService } from './utils.service';

// @ts-ignore
const api = require('@modules/cf-api.js').api;
const api_async = require('@modules/cf-api.js').api_async;

/**
 * Simple wrapper for feathers
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService<T extends User = User> implements OnInit, OnDestroy {

    //override this methode
    // @ts-ignore
    protected model: new (data: Partial<T>) => T = User;

    protected subscription: Subscription = new Subscription();


    public get authenticated(): boolean { return this.auth.authenticated; }
    public get onAuthenticated(): Observable<T | null> { return this._currentUser$.asObservable(); };

    protected get app(): Application { return this.feathers.app; }
    protected get auth(): AuthenticationClient { return this.app.authentication; }

    protected get _currentUser(): T | null {
        return this._currentUser$.value;
    };
    protected _currentUser$: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);

    get user() {
        if (!this._currentUser) {
            let partialUser: any = this.get('auth-user');
            if (partialUser) {
                this.user = partialUser;
            } else if (this._currentUser !== null) {
                this.user = null;
            }
        }
        return this._currentUser;
    }
    set user(u: T | null) {
        console.log('[SET USER]', u);
        try {
            this._currentUser$.next(u ? new this.model(u) : null);
            this.store('auth-user', this._currentUser);
        } catch (err) {
            console.error("[AUTH][ERROR]", err);
        }
    }

    // get hasCustomerLimitedAccess() {
    //     if (this.isCPP() || this.isFab() || this.isOperator()) {
    //         return false;
    //     }
    //     if (this.user?.customer?.hasOption('superdev') || this.user?.customer?.hasOption('diadem') || this.user?.customer?.hasOption('full-access')) {
    //         return false;
    //     }

    //     return true;
    // }

    /**
    * Get current access token synchronious way
    * @return string
    */
    get accessToken() {
        return localStorage.getItem(this.auth.options.storageKey);
    }

    protected isBrowser: boolean = true;

    constructor(protected feathers: KFeathers, protected router: Router, protected cookies: CookieService, protected translate: TranslateService) {
        if (this.feathers.isBrowser && this.feathers.socket) {
            // only use socket on the browser, not in the server while doing ssr
            // Auth the socket to the server to belong in 'authenticated' channel
            if (this.feathers.socket.disconnected && this.accessToken) {
                this.feathers.on('connect', async () => {
                    this.feathers.emit('create', 'authentication', {
                        strategy: 'jwt',
                        accessToken: this.accessToken
                    }, (error: any, newAuthResult: any) => {
                        this.user = newAuthResult.user;
                        console.log('[SOCKET LOGIN]', newAuthResult);
                        if (error) {
                            console.error('[SOCKET LOGIN]', error);
                        }
                    });
                });
            }
        }

        let session = localStorage.getItem('liveco-cf-session');
        if (session) {
            api.m_session = session;
            api_async.m_session = session;
        }

        // Retrieve current User
        // this.currentUser = await api_defer.auth.get_current_user();
        console.log('AUTH AS', this.user);

        this.subscription.add(this._currentUser$.subscribe(user => {
            console.log('SUB TRIGGERED');

            if (user && user.lang && user.lang !== this.translate.currentLang) {
                this.translate.use(user.lang);             
            }
        }));
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public async getUserRemote(): Promise<T> {
        const { user } = await this.app.get('authentication');
        console.log('[GET REMOTE USER]', user);
        return this.user = user;
    }

    /**
    * Get current access token
    * @return string
    */
    public async getAccessToken(): Promise<string | null> {
        return await this.auth.getAccessToken();
    }

    /**
    * Set current access token
    */
    public async setAccessToken(token: string): Promise<string | null> {
        return await this.auth.setAccessToken(token);
    }

    /** Refresh existing token
     *  Important because service call wont be auth if the token isnt refreshed
     *  Only perfom call is the service isnt already auth
     */
    public async reAuthenticate(force?: boolean, strategy?: string): Promise<AuthenticationResult> {
        try {
            let result = await this.auth.reAuthenticate(force, strategy);
            this.app.set('authentication', result);

            this.user = result['user'];
            console.log('[reAUTH]', this.user);
            return result;
        } catch (err: any) {
            this.user = null;
            console.error(`[ERROR][reAUTH] ${err.message}`);
            throw new Error(`[ERROR][reAUTH] ${err.message}`);
        }
    }

    public async login(username: string, liveco_password: string) {
        console.log('LOGIN');
        const start = Date.now();

        var credentials = { strategy: 'local', username, liveco_password };
        let promise = this.feathers.authenticate(credentials);
        const { user } = await promise;

        this.user = user;
        console.log('[LOGIN]', user);

        const end = Date.now();
        const duration = end - start;
        console.log(`The duration 1 was ${duration}ms`);

        await this.updateCFSession(username, liveco_password);

        const endCF = Date.now();
        const durationCF = endCF - end;
        console.log(`The duration CF was ${durationCF}ms`);

        return promise;
    }

    updateCFSession(username: string, liveco_password: string) {
        return new Promise<void>(async (resolve, reject) => {
            let res = { session: '' };
            api.m_session = undefined;
            res = api.auth.create_session(username, { password: liveco_password, expiry: 12 * 60 });
            this.store('cf-session', res.session);
            this.store('cf-session-date', moment().add(12, 'h').toISOString());
            if (res && res.session) {
                this.store('cf-session', res.session);
                api.m_session = res.session;
                api_async.m_session = res.session;
            }

            // Store COOKIE (for splitview) with wildcard domain name
            const cookie = api.auth.login(username, liveco_password);
            if (cookie) {
                const expiry = moment(cookie['expiry_time']).toDate();
                const domain = `.${environment.api.domain}`;
                this.cookies.deleteAll('/', domain, true);
                this.cookies.set('user_id', cookie['user_id'], expiry, '/', domain, true);
                this.cookies.set('user_hash', cookie['user_hash'], expiry, '/', domain, true);
                this.cookies.set('expiration_date', cookie['expiration_date'], expiry, '/', domain, true);
                this.cookies.set('csrf-token', cookie['csrf_token'], expiry, '/', domain, true);
                await UtilsService.sleep(500);
                // Cookie.setCookie('user_id', cookie['user_id'], expiry, '/');
                // Cookie.setCookie('user_hash', cookie['user_hash'], expiry, '/');
                // Cookie.setCookie('expiration_date', cookie['expiration_date'], expiry, '/');
                // Cookie.setCookie('csrf-token', cookie['csrf_token'], expiry, '/');
            }

            resolve();
        });
    }

    hasCFSession(): boolean {
        return !environment.production ||
            this.cookies.check('user_id') &&
            this.cookies.check('user_hash') &&
            this.cookies.check('expiration_date') &&
            this.cookies.check('csrf-token') &&
            this.get('cf-session') && 
            this.get('cf-session-date') && moment(this.get('cf-session-date')).isAfter(moment());
    }


    /**
   * Remove all data stored in localstorage
   */
    public logout() {
        try {
            this.remove('auth-user');
            this.remove('cf-session');
            this.remove('cf-session-date');
            this.feathers.logout();
            this.router.navigate(['/login']);

            // // Remove COOKIE 
            const domain = `.${environment.api.domain}`;
            this.cookies.deleteAll('/', domain, true);
            // this.cookies.delete('user_id');
            // this.cookies.delete('user_hash');
            // this.cookies.delete('expiration_date');
            // this.cookies.delete('csrf-token');
        } catch (err) {
            console.error('ERR', err);
        }
    }

    /** Handies */
    resetUser() {
        this.user = null;
    }

    /**
    //  * Check if user is root
    //  */
    // isRoot(): boolean {
    //   return this.hasRole('Root');
    // }

    // /**
    //  * Check if user is admin
    //  * @param roleName
    //  */
    // hasRole(roleName: string): boolean {
    //   let user: T | null = this.user;
    //   return user && user.role && _.isString(user.role.name) ? user.role.name === roleName : false;
    // }

    /**
     * Get data stored in LocalStorage
     * @param name 
     */
    public get(name: string) {
        let item = localStorage.getItem(environment.slug + '-' + name);
        if (item) {
            try {
                return JSON.parse(item);
            } catch (err) {
                return item;
            }
        }
        return null;
    }

    /**
     * Remove data from Localstorage
     * @param name 
     */
    public remove(name: string) {
        localStorage.removeItem(environment.slug + '-' + name);
    }

    /**
     * Store data in Localstorage
     * @param name 
     * @param data 
     */
    public store(name: string, data: any) {
        localStorage.setItem(environment.slug + '-' + name, _.isString(data) ? data : JSON.stringify(data));
    }

    isCPP(): boolean {
        return this.hasRole([USER_TYPE_CPP, USER_TYPE_ADMIN]);
    }

    isFab(): boolean {
        return this.hasRole([USER_TYPE_FAB]);
    }

    isOperator(): boolean {
        return this.hasRole([USER_TYPE_OPERATOR]);
    }

    isClient(): boolean {
        return this.hasRole([USER_TYPE_CLIENT]);
    }

    hasRole(names: string[]): boolean {
        if (!this.user) return false;
        return names.some(name => this.user!.attributes_name.includes(name));
    }

    /**
     * FOR DISPLAY PURPOSE ONLY (NOT FOR SECURITY)
     * @returns 
     */
    getUserType() {
        const user = this.user;
        const attributes_names = user?.attributes_name;
        if (user && attributes_names && attributes_names.length) {
            if (attributes_names.includes(USER_TYPE_ADMIN)) {
                return USER_TYPE_ADMIN;
            }
            if (attributes_names.includes(USER_TYPE_CPP)) {
                return USER_TYPE_CPP;
            }
            if (attributes_names.includes(USER_TYPE_FAB)) {
                return USER_TYPE_FAB;
            }
            if (attributes_names.includes(USER_TYPE_OPERATOR)) {
                return USER_TYPE_OPERATOR;
            }
            if (attributes_names.includes(USER_TYPE_CLIENT)) {
                return USER_TYPE_CLIENT;
            }
        }
        return 'other';
    }
}

