import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ViewComponent } from '../view/view.component';
import { AuthService } from 'src/app/services/auth.service';
import { ResolverService } from 'src/app/services/api/resolver.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-form-group',
    templateUrl: './form-group.component.html',
    styleUrls: ['./form-group.component.scss'],
})
export class FormGroupComponent<T> extends ViewComponent implements OnInit, AfterContentInit, OnDestroy {

    /** Name of subform in parent Form */
    @Input() name?: string;
    @Input() parent?: FormGroup;

    protected _valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Output() valid$Change: EventEmitter<BehaviorSubject<boolean>> = new EventEmitter<BehaviorSubject<boolean>>();
    @Input() set valid$(value: BehaviorSubject<boolean>) {
        this._valid$ = value;
        this.valid$Change.emit(this._valid$);
    }
    get valid$(): BehaviorSubject<boolean> {
        return this._valid$;
    }
    get valid(): boolean {
        return this._valid$.value;
    }

    protected _form: FormGroup;
    @Output() formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Input() set form(value: FormGroup) {
        this._form = value;
        this.formChange.emit(this._form);
    };
    get form(): FormGroup {
        return this._form;
    }

    @Output() modelChange: EventEmitter<T> = new EventEmitter<T>();
    protected _model: T;
    @Input() set model(value: T) {
        if (this.service) {
            this.service.current = value;
        } else {
            if (this._modelBuilder && !(value instanceof this._modelBuilder)) {
                this._model = new this._modelBuilder(value);
            } else {
                this._model = value;
            }
        }
        this.modelChange.emit(this.model);
    }
    get model(): T {
        if (this.service) {
            return this.service.current;
        }
        return this._model;
    }
    protected _modelBuilder?: new (data: any) => T = undefined;

    get controls(): { [x: string]: FormControl; } {
        return this.form.controls as { [x: string]: FormControl; };
    }

    protected service: ResolverService<any> | undefined;

    // Facto View Component 
    // subscriptions: Subscription[] = [];

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected s_alert: AlertService,
        protected s_loader: LoaderService,
        protected s_auth: AuthService,
        protected translate: TranslateService
    ) {
        super(route, router, s_alert, s_loader, s_auth, translate);
    }

    ngOnInit(): void {
        this.subscription.add(this.formChange.subscribe(
            (form) => {
                console.log(`[FORM][${this.name || this.constructor.name}][FORM CHANGE]`, form);
                if (!_.isNil(form)) {
                    this.subscription.add(
                        this.form.valueChanges.subscribe(() => {
                            // console.log(`[FORM][${this.name || this.constructor.name}][FORM VALUE CHANGES]`, this.form.getRawValue());
                            if (this.service) { this.service.current.patch(this.form.getRawValue()); }
                            this._valid$.next(this.form.valid);
                            // console.log('UPDATE VALUES', this.form.valid)
                            // const valid = this.isFormValid(false);
                            // this._valid$.next(valid);
                        }));
                    // this.subscription.add(this._valid$.subscribe());

                }
            }
        ));
        this.initForm();
        this.link();
        this.subscription.add(this.modelChange.subscribe(this.patch.bind(this)));
        if (this.service) this.subscription.add(this.service.current$.subscribe(this.patch.bind(this)));
    }

    async ngAfterContentInit() {
        super.ngAfterContentInit();
        await this.initValidators();
        await this.initSubscriptions();
        // if (this.model) this.patch(this.model);
    }


    //#region LIFECYCLE
    initForm() {
        console.log(`[FORM][${this.name || this.constructor.name}][INIT FORM]`);
    }

    async initValidators() {
        console.log(`[FORM][${this.name || this.constructor.name}][INIT VALIDATORS]`);
    }

    async initSubscriptions() {
        console.log(`[FORM][${this.name || this.constructor.name}][INIT SUBSCRIPTIONS]`);
    }

    submitForm() {
        console.log(`[FORM][${this.name || this.constructor.name}][SUBMIT FORM]`);
    }

    resetForm(control: AbstractControl) {
        console.log(`[FORM][${this.name || this.constructor.name}][RESET FORM]`);
        this.controlAction(control, (control) => {
            if (control instanceof FormArray) {
                control.clear();
            } else if (control instanceof FormControl) {
                control.reset();
            }
        });
    };

    resetFormArray(control: AbstractControl) {
        this.controlAction(control, (control) => {
            if (control instanceof FormArray) {
                const group = control as FormArray;
                while (group.length) {
                    group.removeAt(0);
                }
            }
        });
    }

    protected link() {
        if (this.parent && this.name) {
            console.log(`[FORM][${this.name}][LINKING FORM]`);
            this.parent.setControl(this.name, this.form);
            console.log(this.parent);
        }
    }

    protected unlink() {
        if (this.parent && this.name) {
            console.log(`[FORM][${this.name}][UNLINKING FORM]`);
            this.parent.removeControl(this.name);
        }
    }


    //#endregion
    isFormValid(emit: boolean = true): boolean {
        if (_.isNil(this.form)) {
            return false;
        }
        this.triggerValidation(this.form, emit);
        this.valid$.next(this.form.valid);
        return this.valid;
    }

    protected triggerValidation(control: AbstractControl, emit: boolean) {
        this.controlAction(control, (control) => {
            control.updateValueAndValidity({ onlySelf: true, emitEvent: emit });
        });
    }

    protected controlAction(
        control: AbstractControl,
        action: (control: AbstractControl) => void
    ) {
        if (control instanceof FormGroup) {
            const group = control as FormGroup;

            for (const field in group.controls) {
                const c = group.controls[field];

                this.controlAction(c, action);
            }
        } else if (control instanceof FormArray) {
            const group = control as FormArray;

            for (const field in group.controls) {
                const c = group.controls[field];

                this.controlAction(c, action);
            }
        }
        action(control);
    }

    // ngOnDestroy(): void {
    //   this.subscriptions.forEach(s => s.unsubscribe())
    // }

    patch(model: { [key: string]: any; }, select?: string[]) {
        if (model) {
            // this.model = model as any;
            // if (this._modelBuilder) {
            //     this._model = new this._modelBuilder(model);
            // }
            if (this?.controls) {
                let keys = Object.keys(this.controls);
                if (select && _.isArray(select)) {
                    keys = select;
                }
                // console.trace(`[FORM][${this.name || this.constructor.name}][PATCHING]`, model, keys)
                for (let key of keys) {
                    if (model[key] !== undefined && !_.isEqual(this.controls[key].value, model[key])) {
                        this.controls[key].patchValue(model[key], {
                            emitEvent: false,
                        });
                    }
                }
                this.isFormValid(true);
            }
        } else {
            console.warn(`model is Nil, patching entity ${typeof model} canceled`);
        }
    }
}