import * as _ from "lodash";
import { BaseModel, Id } from "./base.model";
import { FlowType } from "./proofreading.model";



export enum InternStateName {
    NONE = 'none',
    READY = 'ready',
    WIP = 'wip',
    CHECK_AUTO_CTRL = 'check-auto-control',
    CHECK_QUALITY = 'check-quality',
    CHECK_PROD = 'check-prod',
    CHECK_REPRO = 'check-repro',
    CHECK_CPP = 'check-cpp',
    // Constrol
    CONTROL_VALIDATED = 'control-validated',
    CONTROL_FAILED = 'control-failed',

    // ERROR = 'control-approved',
    INTERN_FINISHED = 'intern-finished',
    PROD_INTERN_REJECTED = 'prod-intern-rejected',
    CPP_INTERN_REJECTED = 'cpp-intern-rejected',

    PR_CLIENT_INPROGRESS = 'pr-client-inProgress',
    GENERATING = 'generating',

    //DELIVERY
    TO_DELIVER = 'to-deliver',
    CONTROL_DELIVERY_FAILED = 'control-delivery-failed',
    CONTROL_DELIVERY_VALIDATED = 'control-delivery-validated',
    DELIVERED = 'delivered',
}

export enum ExternStateName {
    DELIVERED = 'delivered',
    POST_VALIDATION = 'post-validation',
    REJECTED = 'rejected',
    APPROVED = 'approved',
    RESTART = 'restart',
    PAUSED = 'paused',
    FEEDBACK_CLIENT = 'feedback-client',
    FEEDBACK_INFO = 'feedback-info',
    IN_PROGRESS = 'in-progress',
    SEND_PR_CPP = 'send-pr-cpp',
    SEND_PR_CLIENT = 'send-pr-client',
    INIT = 'init',
    IN_PRODUCTION = "in-production"
}

export enum ExternStatePayloadFlag {
    CUSTOMER_CAN_EDIT = 'customer-can-edit',
    CUSTOMER_CAN_APPROVE = 'customer-can-approve',
    CUSTOMER_INFO_CAN_APPROVE = 'customer-info-can-approve',
    EZD_CAN_REUPLOAD_FILE = 'ezd-can-reupload-file'
}

export enum StateConditionOptions {
    EXTERN_APPROVED = 'EXTERN_APPROVED',
    APPROVER_DOCUMENT = "APPROVER_DOCUMENT",
    FICHE_TEXT = "FICHE_TEXT",
}

export interface IState {
    _id?: Id;
    flow: FlowType[];
    type: 'intern' | 'extern';
    slug: string,
    opts: string[],
    next?: { [key: string]: IStateCondition; };
    previous?: { [key: string]: IStateCondition; };
    payload?: any;
}

export interface IStateCondition {
    conditions: string[];
}

export class State extends BaseModel<IState> implements IState {
    flow: FlowType[];
    type: 'intern' | 'extern';
    slug: ExternStateName | InternStateName;
    opts: string[];
    next?: { [key: string]: IStateCondition; } | undefined;
    previous?: { [key: string]: IStateCondition; } | undefined;
    payload?: any;

    constructor(data: IState) {
        super(data);
        _.assign(this, data);
    }

    hasPayloadFlag(payload_opts: ExternStatePayloadFlag) {
        return this.payload?.flags && this.payload?.flags[payload_opts];
    }
}

