import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { LivecoAPIService } from 'src/app/services/liveco-api.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormGroupComponent } from 'src/app/shared/pages/form-group/form-group.component';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.page.html',
    styleUrls: ['./reset-password.page.scss']
})
export class ResetPasswordPage extends FormGroupComponent<any> implements OnInit {

    @HostListener('keydown.enter', ['$event'])
    onEnterKey(event: KeyboardEvent) {
        this.onSubmit();
    }

    isLoading: boolean = false;
    showPassword: boolean = false;

    inputLogin: string = '';
    jwtToken: string = '';

    pageMode: 'ask-login' | 'set-password' = 'ask-login';

    constructor(
        protected dialog: MatDialog,
        protected route: ActivatedRoute,
        protected s_liveco: LivecoAPIService,
        protected translate: TranslateService,
        private fb: FormBuilder,
        protected router: Router,
        protected s_alert: AlertService,
        protected s_loader: LoaderService,
        protected s_auth: AuthService
    ) {
        super(route, router, s_alert, s_loader, s_auth, translate);
        if (this.route.snapshot.queryParams.token) {
            this.jwtToken = this.route.snapshot.queryParams.token;
            this.pageMode = 'set-password';
        }
    }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            newPassword: new FormControl('', [Validators.required]),
            newPasswordConfirm: new FormControl('', [Validators.required])
        }, { validators: this.checkPasswords });
    }

    checkPasswords: ValidatorFn = (controls: AbstractControl): ValidationErrors | null => {
        let newPassword = controls.get('newPassword')?.value;
        let newPasswordConfirm = controls.get('newPasswordConfirm')?.value;
        return newPassword === newPasswordConfirm ? null : { notSame: true };
    };

    toggleVisibility(): void {
        this.showPassword = !this.showPassword;
    }

    public changeFormLoading = (e: boolean): void => {
        this.isLoading = e;
    };

    isDisabled() {
        return !this.inputLogin || this.inputLogin.length < 1;
    }

    backToLogin() {
        this.router.navigate(['/login']);
    }

    async onSubmit() {
        if (this.pageMode === 'ask-login') {
            if (!this.isDisabled()) {
                this.isLoading = true;

                let result = false;

                try {
                    result = await this.s_liveco.create('/reset-password/request', { login: this.inputLogin });
                } catch (error) {
                    console.error(error);
                }

                this.isLoading = false;

                let title = this.translate.instant('RESET_PASSWORD_DIALOG.ERROR.TITLE');
                let text = this.translate.instant('RESET_PASSWORD_DIALOG.ERROR.TEXT');

                if (result) {
                    title = this.translate.instant('RESET_PASSWORD_DIALOG.REQUEST.SUCCESS.TITLE');
                    text = this.translate.instant('RESET_PASSWORD_DIALOG.REQUEST.SUCCESS.TEXT');
                }

                this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        title: title,
                        text: text,
                        valid: 'YES',
                        noConfirm: true,
                        rawText: false
                    }
                })
                    .afterClosed()
                    .subscribe((res) => {
                        if (result) {
                            this.router.navigate(['/login']);
                        }
                    });
            }
        } else if (this.pageMode === "set-password") {
            this.isLoading = true;

            let result = false;

            try {
                result = await this.s_liveco.create('/reset-password/set', { token: this.jwtToken, password: this.controls.newPassword.value });
            } catch (error) {
                console.error(error);
            }

            this.isLoading = false;

            let title = this.translate.instant('RESET_PASSWORD_DIALOG.ERROR.TITLE');
            let text = this.translate.instant('RESET_PASSWORD_DIALOG.ERROR.TEXT');

            if (result) {
                title = this.translate.instant('RESET_PASSWORD_DIALOG.SET.SUCCESS.TITLE');
                text = this.translate.instant('RESET_PASSWORD_DIALOG.SET.SUCCESS.TEXT');
            }

            this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: title,
                    text: text,
                    valid: 'YES',
                    noConfirm: true,
                    rawText: false
                }
            })
                .afterClosed()
                .subscribe((res) => {
                    if (result) {
                        this.router.navigate(['/login']);
                    }
                });
        }
    }
}

