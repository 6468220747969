import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CFAPIService } from 'src/app/services/api.service';
import { IUser, User, USER_LANGUAGES } from 'src/app/models/users.model';
import { FormGroupComponent } from 'src/app/shared/pages/form-group/form-group.component';
import { Group, IGroup } from 'src/app/models/groups.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

import { TITLESPAGES } from 'src/app/models/titlePage.model';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

// @ts-ignore
// const api = require('@modules/cf-api.js').api;;
// const api_async = require('@modules/cf-api.js').api_async;

@Component({
    selector: 'app-my-account-info',
    templateUrl: './my-account-info.component.html',
    styleUrls: ['./my-account-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MyAccountInfoComponent extends FormGroupComponent<IUser> implements OnInit {
    separatorKeysCodes: number[] = [ENTER, COMMA];

    groupFilter: FormControl = new FormControl('');

    groups: Group[] = [];
    get filteredGroups(): Group[] { return this.groups.filter(g => this.onFilter(g) && !this.controls.groups.value.includes(g._id)); };

    USER_LANGUAGES = USER_LANGUAGES;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected s_alert: AlertService,
        protected cd: ChangeDetectorRef,
        protected s_loader: LoaderService,
        protected dialog: MatDialog,
        private fb: FormBuilder,
        protected apiCF: CFAPIService,
        protected s_auth: AuthService,
        protected titleService: Title,
        protected translate: TranslateService
    ) {
        super(route, router, s_alert, s_loader, s_auth, translate);
        this.titleService.setTitle(TITLESPAGES.ACCOUNT_TITLE);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    initForm() {
        this.form = this.fb.group({
            username: new FormControl('', [Validators.required]),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.email, Validators.required]),
            customer: new FormControl({ value: '', disabled: !this.creating }, [Validators.required]),
            groups: new FormControl([]),
            language: new FormControl('fr', [Validators.required]),
        });
    }

    async initSubscriptions(): Promise<void> {
        // this.subscription.add(this.modelChange.subscribe(this.patch.bind(this)));
        this.model = this.currentUser || new User({});
    }

    //#region groups
    // resetGroups(){
    //     this.controls.groups.setValue();
    // }

    onFilter(group: IGroup) {
        let value = this.groupFilter.value?.toLowerCase();
        if (value) {
            return group.name.toLowerCase().includes(value);
        }
        else {
            return true;
        }
    }

    removeGroup(id: string) {
        let index = (this.controls.groups.value as string[]).findIndex(v => v === id);
        if (index >= 0) {
            (this.controls.groups.value as string[]).splice(index, 1);
        }
    }

    addGroup(event: MatAutocompleteSelectedEvent) {
        let id = event.option.value;
        this.controls.groups.patchValue(_.concat(this.controls.groups.value as string[], [id]));
        this.groupFilter.reset();
    }

    add(event: MatChipInputEvent): void {
        event.chipInput!.clear();
        this.groupFilter.reset();
    }

    getGroups(id: string) {
        return this.groups.find(g => g._id === id);
    }

    resetPassword() {
        if (this.currentUser?._id)
            this.router.navigate(['user/reset-password/' + this.currentUser._id + '/0']);
    }
}
