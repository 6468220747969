<form id="login-form" [formGroup]="form">
    <div class="logo"><img src="assets/svg/LogoDiadem.svg" alt="logo-diadem"></div>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'USERNAME'|translate}}</mat-label>
        <input matInput formControlName="username" [ngClass]="{ 'is-invalid': controls.username.errors }" />
        <mat-error *ngIf="controls.username.errors"> {{'INVALID_USERNAME'|translate}} </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100 mb-1">
        <mat-label>{{'PASSWORD'|translate}}</mat-label>
        <input matInput type="password" formControlName="password" [ngClass]="{ 'is-invalid': controls.password.errors }" />
        <mat-error *ngIf="controls.password.errors"> {{'INVALID_PASSWORD'|translate}} </mat-error>
    </mat-form-field>
    <a class="text-blue-500 hover:text-blue-700 underline cursor-pointer mb-3" (click)="onForgotPassword()">{{'FORGOT_PASSWORD'|translate}}</a>
    <div class="btn-container text-right">
        <button mat-flat-button [disabled]="isDisabled() || isLoading" color="accent" type="button" (click)="onSubmit()"> {{'CONNECTION'|translate}} </button>
    </div>
</form>