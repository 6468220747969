

export const svg_icons = [
    ['check-mark', '../assets/svg/icon-checkmark.svg'],
    ['playlist-checked', '../assets/svg/icon-playlist-checked.svg'],
    ['awe-file', '../assets/svg/awe-file.svg'],
    ['awe-folder', '../assets/svg/awe-folder.svg'],
    ['ed-hand', '../assets/svg/easydiadem.svg'],
    ['compare_arrows', '../assets/svg/compare_arrows.svg'],
    ['packz-logo', '../assets/svg/packz-logo.svg'],
    ['visibility', '../assets/svg/visibility.svg'],
    ['user-circle', '../assets/svg/awe-user-circle.svg'],
    ['folder-limited', '../assets/svg/folder_limited.svg'],
];