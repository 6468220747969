import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { environment } from 'src/environments/environment';
import { AlertService } from "./alert.service";
import { LoaderService } from "./loader.service";

// @ts-ignore
const api = require('@modules/cf-api.js').api;;
const api_async = require('@modules/cf-api.js').api_async;

export interface IProgress {
    activity: string,
    title: string,
    value: number;
}

@Injectable({ providedIn: 'root' })
export class CFAPIService {

    public resumed: EventEmitter<any> = new EventEmitter<any>();

    intervalTime = 5000;
    initialTimeOut = 5000;
    maxTry = (2 * 60) / (this.intervalTime / 1000);

    defaultErrCallback: (err: any) => void = (err: any) => {
        console.error(err);
        let msg = err?.message || err?.contents?.message || 'SOMETHING_WRONG_HAPPENED';
        if (_.isString(err?.contents)) {
            msg = err?.contents;
        };
        let status = err?.status;
        this.loader.loading = false;
        if (status === 403) {
            this.router.navigate(["error", 403], {
                queryParams: { message: msg }
            });
        } else {
            this.alert.error(msg);
        }
    };

    constructor(
        protected alert: AlertService,
        protected loader: LoaderService,
        protected router: Router
    ) { }

    async resume(whitepaper_name: string, input_name: string, cb?: (res: any) => void, error?: (err: any) => void, loading?: (loading: boolean) => void, progress?: EventEmitter<IProgress>) {
        const p = sessionStorage.getItem(`liveco-api-progress/${whitepaper_name}/${input_name}`);
        // console.log("loading", p);
        if (p) {
            if (loading) loading(true);
            await this.retry(whitepaper_name, input_name, p, cb, error, progress);
            if (loading) loading(false);
        }
    }


    process(whitepaper_name: string, input_name: string, variables: any, callback?: (res: any) => void, errCallback?: (err: any) => void, onProgress?: EventEmitter<IProgress>) {
        if (!errCallback)
            errCallback = this.defaultErrCallback;
        api_async.hub.process_from_whitepaper_with_variables(
            whitepaper_name,
            input_name,
            variables,
            this.initialTimeOut / 1000, // time out 
            (res: any) => {
                this.onProcessResult(whitepaper_name, input_name, res, callback, errCallback, onProgress || this.loader.progress);
            },
            errCallback
        );
    }

    protected async onProcessResult(whitepaper_name: string, input_name: string, response: any, callback?: any, errCallback?: any, onProgress?: EventEmitter<IProgress>) {
        // console.log("[API SERVICE] RESULT", response);
        // check if response is workable report and timed_out is true 
        if (response.workable_id && response.timed_out) {
            // retry while maintaining progress update
            await this.retry(whitepaper_name, input_name, response.workable_id, callback, errCallback, onProgress);

        } else if (response === null || (response.workable_id && response.no_results)) {
            console.error(response);
            errCallback({ message: "CLOUDFLOW is in error state. API call failed, please retry." });
        }
        else if (response && response.status && _.isNumber(response.status) && response.status !== 200 && response.status !== 110 && response.status !== 100) {
            errCallback(response);
        }
        else if (response && response.status && _.isNumber(response.status) && (response.status === 110 || response.status === 100) && response.message) {
            this.router.navigate(["error", 403], { queryParams: { message: response.message } });

        }
        else {
            if (onProgress) {
                onProgress.emit(undefined);
            }
            /** No Troubles */
            if (callback) {
                callback(response);
            }
        }
    }

    async retry(whitepaper_name: string, input_name: string, workable_id: string, callback?: any, errCallback?: any, onProgress?: EventEmitter<IProgress>) {
        let res: any = { done: false };
        let tryCount = 0;
        if (!errCallback) {
            errCallback = this.defaultErrCallback;
        }
        // store current workable_id
        sessionStorage.setItem(`liveco-api-progress/${whitepaper_name}/${input_name}`, workable_id);

        while (tryCount < this.maxTry && !res.done) {

            res = await new Promise((resolve, reject) => {
                api_async.hub.process_from_whitepaper_with_variables(
                    environment.whitepapers.livecore,
                    "workable",
                    { id: workable_id },
                    this.initialTimeOut / 1000,
                    resolve,
                    reject
                );
            });


            console.log("[API RETRY RESULT]", res);

            if (_.isObject(res) || (_.isString(res) && res !== 'null')) {
                const result: any = res;
                if (_.isObject(result) && (result as any).progress) {
                    // console.log('[ON PROGRESS]', (result as any).progress);
                    if (onProgress) {
                        onProgress.emit((result as any).progress as IProgress);
                    }
                }

                if (result.state === 'error' || result.aborted) {
                    if (onProgress) {
                        onProgress.emit(undefined);
                    }
                    errCallback();
                    break;
                }

                // else
                if (result.done && callback) {
                    if (onProgress) {
                        onProgress.emit(undefined);
                    }
                    callback(result);
                    break;
                }

            }

            // else if (result === null || result?.trim() === 'null') {
            //     if (onProgress) {
            //         onProgress.emit(undefined);
            //     }
            // }
            // else 

            if (tryCount === this.maxTry - 1) {
                if (onProgress) {
                    onProgress.emit(undefined);
                }
                errCallback();
                break;
            }
            tryCount++;
            // console.log("Request Timed Out, retrying...", tryCount, result);
            await this.timeout(this.intervalTime);
        }

        // store current workable_id
        sessionStorage.removeItem(`liveco-api-progress/${whitepaper_name}/${input_name}`);
    }


    timeout(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

