<div class="nav-bar h-full">
    <!-- && !hasLimitedAccess() -->
    <ng-container *ngIf="isCPP() || isFab() || isOperator() || isClient()">
        <a class="item-menu px-3 h-full" [routerLink]="appRoutes.home">
            <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.home)}">{{ "MENU.PROJECTS" | translate }}</button>
        </a>
    </ng-container>
    <ng-container *ngIf="isCPP() || isFab()">
        <a class="item-menu px-3 h-full" [routerLink]="appRoutes.printers">
            <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.printers)}">{{ "MENU.PRINTER" | translate }}</button>
        </a>
    </ng-container>
    <ng-container *ngIf="isCPP() || isFab() || isOperator()">
        <a class="item-menu px-3 h-full" [routerLink]="appRoutes.colors">
            <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.colors)}">{{ "MENU.COLORS" | translate }}</button>
        </a>
    </ng-container>
    <ng-container *ngIf="isCPP()">
        <a class="item-menu px-3 h-full" [routerLink]="appRoutes.users">
            <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.users)}">{{ "MENU.USERS" | translate }}</button>
        </a>
    </ng-container>
    <ng-container *ngIf="!isClient()">
        <a class="item-menu px-3 border-tw-disabled border-l-[1px] h-full" [href]="appRoutes.kiosk" target="_blank">
            <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes.kiosk)}"> {{ "MENU.KIOSK" | translate }} </button>
        </a>
    </ng-container>
    <!-- <ng-container *ngIf="isCPP() || isClient() || isOperator()">
        <a class="item-menu px-3 border-tw-disabled border-l-[1px] h-full" [routerLink]="appRoutes['ed-dashboard']">
            <button mat-menu-item [ngClass]="{'selected': isCurrentUrl(appRoutes['ed-dashboard'])}">
                <mat-icon [svgIcon]="'ed-hand'" class="text-tw-text-dark !mr-2"></mat-icon>
                <div class="flex">{{ "MENU.EASYDIADEM" | translate}}</div>
            </button>
        </a>
    </ng-container> -->
    <a class="item-menu px-3 border-tw-disabled border-l-[1px] h-full" [routerLink]="appRoutes.account">
        <button mat-menu-item class="flex flex-row justify-center items-center" [ngClass]="{'selected': isCurrentUrl(appRoutes.account)}">
            <mat-icon class="mr-2">account_circle</mat-icon>
            <div class="flex">{{ userFullName && userFullName !== "" ? userFullName : ("MENU.ACCOUNT" | translate) }}</div>
        </button>
    </a>
    <button mat-menu-item class="!h-full content-fit no-bar" [matTooltip]="'MENU.LOG_OUT' | translate" (click)="logout()">
        <mat-icon class="!text-tw-error m-0 p-0 mx-2">power_settings_new</mat-icon>
    </button>
    <app-notif-list class="flex h-full"></app-notif-list>
    <div class="item-menu-search d-flex flex-row justify-content-center align-items-center" (mouseenter)="searchOnMouseEnter()" (mouseleave)="searchOnMouseLeave()">
        <input #searchInput class="d-flex flex-column justify-content-center align-items-center" [placeholder]="('SEARCH' | translate) + '...'" (keyup.enter)="makeSearch()" [(ngModel)]="sampleModel" (ngModelChange)="updateDisplay()" />
        <mat-icon #searchIcon class="d-flex flex-column justify-content-center align-items-center" (click)="makeSearch()">search</mat-icon>
    </div>
</div>