<div class="container">
    <form class="h-100 d-flex flex-column" [formGroup]="form" #formDirective="ngForm">
        <div class="d-flex flex-column mt-2 justify-content-between align-items-center">
            <ng-container *ngIf="this.model">
                <div class="d-flex mt-4 w-100 justify-content-center">
                    <div class="d-flex flex-column w-30 mr-5">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>{{'USER.USERNAME'| translate}}</mat-label>
                            <input matInput formControlName="username" class="w-100" placeholder="" required>
                            <span class="text input-error w-100" *ngIf="controls.username.hasError('required') && controls.username.pristine === false">
                                {{'REQUIRED'| translate}}
                            </span>
                        </mat-form-field>
                    </div>
                    <div class="d-flex flex-column w-30">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>{{'EMAIL'| translate}}</mat-label>
                            <input matInput formControlName="email" class="w-100" placeholder="">
                        </mat-form-field>
                        <span class="text input-error" *ngIf="controls.email.hasError('email')">
                            {{'VALID_EMAIL'| translate}}
                        </span>
                    </div>
                </div>
                <div class="d-flex mt-4 w-100 justify-content-center">
                    <div class="d-flex flex-column w-30 mr-5">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>{{'USER.FIRSTNAME'| translate}}</mat-label>
                            <input matInput formControlName="firstName" class="w-100" placeholder="" required>
                            <span class="text input-error w-100" *ngIf="controls.firstName.hasError('required') && controls.firstName.pristine === false">
                                {{'REQUIRED'| translate}}
                            </span>
                        </mat-form-field>
                    </div>
                    <div class="d-flex flex-column w-30">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>{{'USER.LASTNAME'| translate}}</mat-label>
                            <input matInput formControlName="lastName" class="w-100" placeholder="" required>
                            <span class="text input-error w-100" *ngIf="controls.lastName.hasError('required') && controls.lastName.pristine === false">
                                {{'REQUIRED'| translate}}
                            </span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="d-flex mt-4 w-100 justify-content-center">
                    <div class="d-flex flex-column w-30 mr-5">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>{{ "LANG_TRANSLATED" | translate }}</mat-label>
                            <mat-select formControlName="language" class="w-100">
                                <mat-option *ngFor="let lang of USER_LANGUAGES | keyvalue" [value]="lang.value.key">{{lang.value.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="d-flex flex-column w-30">
                    </div>
                </div>
                <div class="reset-password d-flex mt-5 w-100">
                    <div class="btn-reset-password" (click)="resetPassword()">
                        <span class="txt-btn">
                            {{'USER.PASSWORD.RESET' | translate}}
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </form>
</div>